<template>
   <p class="clearfix mb-0 text-center">
    <span class="mt-25" style="color: #B2BEC3">
      COPYRIGHT © 2020 by EAERA LTD. All rights reserved.
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
